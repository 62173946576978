import { ServicesLoaders } from '@wix/thunderbolt-symbols'
// import { EnvironmentDefinition} from '@wix/environment'
import { NamedSignalsServiceDefinition } from '@wix/thunderbolt-services'
// import { environmentServiceConfig } from './configs/environmentServiceConfig'
import namedSignalsServiceConfig from './configs/namedSignalsServiceConfig'

export const serviceLoaders: ServicesLoaders = {
	testService: () => import('feature-test-service/service' /* webpackChunkName: "platformTestService" */),
	namedSignalsService: () => import('feature-named-signals-service/service' /* webpackChunkName: "platformNamedSignalsService" */),
	// environmentService: () => import('feature-environment-service/service' /* webpackChunkName: "environmentServiceWorker" */)
}

export const serviceConfigs: { [serviceDefinition: string]: any } = {
	[NamedSignalsServiceDefinition as string]: namedSignalsServiceConfig,
	// [EnvironmentDefinition as string]: environmentServiceConfig,
}
